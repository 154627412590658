<template>
    <div class="pageBox">
        <el-table 
        size="large" 
        :data="tableData" 
        style="width: 100%">
            <el-table-column type="index" label="序号" align="center" width="60"/>
            <el-table-column prop="username" label="操作用户"  align="center" />
            <el-table-column prop="requestIp" label="请求IP" align="center" />
            <el-table-column prop="description" label="描述"  align="center" />
            <el-table-column prop="method" label="方法名称" align="center" />
            <el-table-column prop="params" label="参数" align="center" />
            <el-table-column prop="logType" label="日志类型" align="center" />
            <el-table-column label="创建时间" align="center">
                <template #default="scope">
                    {{moment(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss')}}
                </template>
            </el-table-column>
        </el-table>
        <el-pagination 
        background 
        :page-size="query.size"
        :current-page="query.page"
        layout="total, prev, pager, next"
        @current-change="pageChange"
        :total="total" />
    </div>
</template>
<script>
import { getErrDetail } from '@/api/log.js'
import moment from 'moment'
export default {
    data(){
        return{
            tableData:[],
            total:0,
            query:{
                page:1,
                size:10,
            }
        }
    },
    mounted(){
        this.GetErrDetail()
    },
    methods:{
        moment,
        GetErrDetail(){
            getErrDetail(this.query).then(r=>{
                this.tableData = r.content
                this.total = r.totalElements
            })
        },
        pageChange(page){
            this.query.page = page
            this.GetErrDetail()
        },
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
}
.el-pagination{
    margin-top: 20px;
    justify-content: center;
}
/deep/.el-pagination.is-background .el-pager li.is-active{
    background-color: #803a15;
}
</style>